
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93RyVVoKkmcHMeta } from "/vercel/path0/pages/[slug].vue?macro=true";
import { default as _404lZJpwxvtooMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as _91slug_93zDXrRL9brpMeta } from "/vercel/path0/pages/airport-transfer/[slug].vue?macro=true";
import { default as indexrNwKqm2b5iMeta } from "/vercel/path0/pages/airport-transfers/index.vue?macro=true";
import { default as how_45to_45get_45to_45el_45nidoJ7TqBBCqL7Meta } from "/vercel/path0/pages/article/how-to-get-to-el-nido.vue?macro=true";
import { default as _91slug_93_46clientHGCXT5rsnmMeta } from "/vercel/path0/pages/booking/[slug].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/vercel/path0/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as cancell4K40oCkPKMeta } from "/vercel/path0/pages/cancel.vue?macro=true";
import { default as _91ref_932b0rdexGzoMeta } from "/vercel/path0/pages/cart/[ref].vue?macro=true";
import { default as indextHkCpcsk7qMeta } from "/vercel/path0/pages/cart/index.vue?macro=true";
import { default as confirmation_46clientd5bmXFsTq3Meta } from "/vercel/path0/pages/confirmation.client.vue?macro=true";
import { default as destinationsjn09LJjFXhMeta } from "/vercel/path0/pages/destinations.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91slug_930PMcaN2wjaMeta } from "/vercel/path0/pages/listings/[slug].vue?macro=true";
import { default as _91ref_93gGhXPd6WKpMeta } from "/vercel/path0/pages/participants/[ref].vue?macro=true";
import { default as paymentqSZWcJbf8VMeta } from "/vercel/path0/pages/payment.vue?macro=true";
import { default as _91slug_93KsbhNI1yQEMeta } from "/vercel/path0/pages/tours/[slug].vue?macro=true";
import { default as indexemJKwxSY7PMeta } from "/vercel/path0/pages/tours/index.vue?macro=true";
import { default as _91slug_93A05yCGdaHqMeta } from "/vercel/path0/pages/transfers/[slug].vue?macro=true";
import { default as index0yFqhYR1szMeta } from "/vercel/path0/pages/transportation/index.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/vercel/path0/pages/[slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/vercel/path0/pages/404.vue")
  },
  {
    name: "airport-transfer-slug",
    path: "/airport-transfer/:slug()",
    component: () => import("/vercel/path0/pages/airport-transfer/[slug].vue")
  },
  {
    name: "airport-transfers",
    path: "/airport-transfers",
    component: () => import("/vercel/path0/pages/airport-transfers/index.vue")
  },
  {
    name: "article-how-to-get-to-el-nido",
    path: "/article/how-to-get-to-el-nido",
    component: () => import("/vercel/path0/pages/article/how-to-get-to-el-nido.vue")
  },
  {
    name: "booking-slug",
    path: "/booking/:slug()",
    component: () => createClientPage(() => import("/vercel/path0/pages/booking/[slug].client.vue"))
  },
  {
    name: "cancel",
    path: "/cancel",
    component: () => import("/vercel/path0/pages/cancel.vue")
  },
  {
    name: "cart-ref",
    path: "/cart/:ref()",
    component: () => import("/vercel/path0/pages/cart/[ref].vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/vercel/path0/pages/cart/index.vue")
  },
  {
    name: "confirmation",
    path: "/confirmation",
    component: () => createClientPage(() => import("/vercel/path0/pages/confirmation.client.vue"))
  },
  {
    name: "destinations",
    path: "/destinations",
    component: () => import("/vercel/path0/pages/destinations.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "listings-slug",
    path: "/listings/:slug()",
    component: () => import("/vercel/path0/pages/listings/[slug].vue")
  },
  {
    name: "participants-ref",
    path: "/participants/:ref()",
    component: () => import("/vercel/path0/pages/participants/[ref].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/vercel/path0/pages/payment.vue")
  },
  {
    name: "tours-slug",
    path: "/tours/:slug()",
    component: () => import("/vercel/path0/pages/tours/[slug].vue")
  },
  {
    name: "tours",
    path: "/tours",
    component: () => import("/vercel/path0/pages/tours/index.vue")
  },
  {
    name: "transfers-slug",
    path: "/transfers/:slug()",
    component: () => import("/vercel/path0/pages/transfers/[slug].vue")
  },
  {
    name: "transportation",
    path: "/transportation",
    component: () => import("/vercel/path0/pages/transportation/index.vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/privatetour-details-PPS-PV-PPUREN",
    component: component_45stub5X4Ei38PMg
  }
]