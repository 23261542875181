import { defineStore } from 'pinia';
import listingsJson from '~/data/listings.json';
import store from '~/data/store.json';
const { countries, rates, reviews } = store;
import { listingsDataBuilder } from '~/utils/listingUtil';
import { getRandomSubset } from '~/utils/objectHelpers';

// Add types for better type checking
interface Listing {
    code: string;
    slug: string[];
    title: string;
    images: string[];
    reviewCount: number;
    isBestSeller: boolean;
    chips: string[];
    reviews: any[];
    ratePlans: Record<string, any>;
    origin: string;
    destination: string;
    content: {
        seo_description: string;
        meta_keywords?: string[];
        featured_highlights?: string[];
        sched: string[];
        duration: string;
        markdown_content: {
            inclusions: string;
            exclusions: string;
            itinerary: Record<string, string>;
            importantNotes?: string;
        };
        pickup: string[];
        dropoff: string[];
    };
    operator: string;
    listing_type: string;
    serviceProvider: {
        id: string;
        name: string;
    };
    updatedAt: string;
}

// Add filter and sort types
interface ListingFilters {
    origin?: string;
    destination?: string;
    listing_type?: string[];
    minPrice?: number;
    maxPrice?: number;
    keywords?: string[];
    serviceProvider?: string;
    hasFeaturedHighlights?: boolean;
}

type SortOption = 'price_asc' | 'price_desc' | 'name_asc' | 'name_desc' | 'popularity';

// Add Review interface
interface Review {
    author?: string;
    reviewRating: number;
    reviewTitle?: string;
    reviewContent?: string;
    nationality?: string;
    date?: string;
}

// Advanced cache implementation
class ListingCache {
    private cache: Map<string, {data: any, timestamp: number}> = new Map();
    private maxAge: number;
    
    constructor(maxAgeMs = 1000 * 60 * 5) { // Default 5 minutes cache
        this.maxAge = maxAgeMs;
    }
    
    get(key: string) {
        const cached = this.cache.get(key);
        if (!cached) return null;
        
        // Check if cache is still valid
        if (Date.now() - cached.timestamp > this.maxAge) {
            this.cache.delete(key);
            return null;
        }
        
        return cached.data;
    }
    
    set(key: string, data: any) {
        this.cache.set(key, {
            data,
            timestamp: Date.now()
        });
    }
    
    clear() {
        this.cache.clear();
    }
    
    // Remove expired items
    cleanup() {
        const now = Date.now();
        for (const [key, value] of this.cache.entries()) {
            if (now - value.timestamp > this.maxAge) {
                this.cache.delete(key);
            }
        }
    }
}

// Process the listings data with error handling
let allListing = [];
try {
    allListing = listingsDataBuilder(listingsJson, rates, reviews);
    console.log(`Successfully loaded ${allListing.length} listings`);
} catch (error) {
    console.error('Error processing listings data:', error);
    allListing = [];
}

// Create a cache instance
const cache = new ListingCache();

// Define the store with improved error handling and typings
export const useListingsStore = defineStore('listings', {
    state: () => ({
        listings: allListing as Listing[],
        rates: rates,
        countries: countries,
        reviews: reviews,
        loadingError: null as string | null,
        isFetching: false,
        lastUpdated: Date.now(),
        curratedListings: ['Airport Transfers',
            'Shared Minivan Transfer',
            'Book a Transportation',
            'El Nido Island Tours and Activities',
            'Private Transfers',
            'Private Transfers with Underground River Tour',
            'Puerto Princesa Underground River Tour',
            'Tours in Puerto Princesa',
            'El Nido Joining Boat Tours',
            'El Nido Private Boat Tours',
        ].map((title) => {
            try {
                return slugLibrary(`/${slugify(title)}`, allListing);
            } catch (error) {
                console.error(`Error creating curated listing for "${title}":`, error);
                return null;
            }
        }).filter(Boolean),
    }),
    actions: {
        // Add an action to reload listings data if needed
        reloadListings() {
            try {
                this.isFetching = true;
                this.listings = listingsDataBuilder(listingsJson, rates, reviews);
                this.loadingError = null;
                this.lastUpdated = Date.now();
                cache.clear(); // Clear cache on reload
                return true;
            } catch (error) {
                console.error('Error reloading listings data:', error);
                this.loadingError = 'Failed to reload listings data';
                return false;
            } finally {
                this.isFetching = false;
            }
        },
        
        // Get relevant reviews for a specific listing code
        getRelevantReviews(listingCode: string, limit = 6) {
            try {
                const cacheKey = `relevantReviews:${listingCode}:${limit}`;
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                // Find the listing
                const listing = this.listings.find(l => l.code === listingCode);
                if (!listing) return [];
                
                // If listing already has filtered reviews, return those
                if (listing.reviews && listing.reviews.length > 0) {
                    const limitedReviews = listing.reviews.slice(0, limit);
                    cache.set(cacheKey, limitedReviews);
                    return limitedReviews;
                }
                
                // Otherwise, filter reviews manually
                // Get keywords from listing data
                const title = listing.title ? listing.title.toLowerCase() : '';
                const metaKeywords = listing.content.meta_keywords || [];
                const featuredHighlights = listing.content.featured_highlights || [];
                
                // Extract keywords from itinerary if available
                const itineraryKeywords = [];
                if (listing.content?.markdown_content?.itinerary) {
                    Object.entries(listing.content.markdown_content.itinerary).forEach(([key, value]) => {
                        itineraryKeywords.push(key.toLowerCase());
                        if (typeof value === 'string') {
                            // Extract key terms from description
                            const valueWords = value.toLowerCase().split(/\s+/);
                            valueWords.forEach(word => {
                                if (word.length > 4 && !['there', 'their', 'these', 'those', 'where', 'which', 'about'].includes(word)) {
                                    itineraryKeywords.push(word);
                                }
                            });
                        }
                    });
                }
                
                // Combine all relevant keywords
                const listingKeywords = [
                    ...title.split(/\s+/),
                    ...metaKeywords,
                    ...featuredHighlights,
                    ...itineraryKeywords
                ].map(word => word.toLowerCase());
                
                // Filter and sort reviews by relevance
                const relevantReviews = this.reviews
                    .filter(rev => {
                        // Only use positive reviews
                        if (!rev.reviewRating || rev.reviewRating < 4) return false;
                        if (!rev.reviewContent) return false;
                        
                        const reviewContent = rev.reviewContent.toLowerCase();
                        const reviewTitle = rev.reviewTitle ? rev.reviewTitle.toLowerCase() : '';
                        
                        // Calculate relevance score
                        let relevanceScore = 0;
                        
                        // Check for exact listing code mention (strongest indicator)
                        if (listingCode && (reviewContent.includes(listingCode.toLowerCase()) || 
                                reviewTitle.includes(listingCode.toLowerCase()))) {
                            relevanceScore += 10;
                        }
                        
                        // Check for title matches
                        if (title && title.split(/\s+/).some(word => word.length > 3 && 
                                (reviewContent.includes(word) || reviewTitle.includes(word)))) {
                            relevanceScore += 5;
                        }
                        
                        // Check for itinerary keyword matches
                        const matchedKeywords = listingKeywords.filter(keyword => 
                            keyword.length > 3 && (reviewContent.includes(keyword) || reviewTitle.includes(keyword))
                        );
                        
                        relevanceScore += matchedKeywords.length;
                        
                        // Check for specific location mentions
                        if (listing.origin && (reviewContent.includes(listing.origin.toLowerCase()) || 
                                        reviewTitle.includes(listing.origin.toLowerCase()))) {
                            relevanceScore += 3;
                        }
                        if (listing.destination && (reviewContent.includes(listing.destination.toLowerCase()) || 
                                        reviewTitle.includes(listing.destination.toLowerCase()))) {
                            relevanceScore += 3;
                        }
                        
                        // Return true if review is relevant enough
                        return relevanceScore > 2;
                    })
                    .sort((a, b) => {
                        // Calculate relevance scores for sorting
                        const scoreA = this.calculateReviewRelevance(a, listing, listingKeywords);
                        const scoreB = this.calculateReviewRelevance(b, listing, listingKeywords);
                        return scoreB - scoreA;
                    })
                    .slice(0, limit);
                
                // Cache the result
                cache.set(cacheKey, relevantReviews);
                
                return relevantReviews;
            } catch (error) {
                console.error(`Error getting relevant reviews for "${listingCode}":`, error);
                return [];
            }
        },
        
        // Helper method to calculate review relevance score
        calculateReviewRelevance(review: Review, listing: Listing, keywords: string[]) {
            if (!review.reviewContent) return 0;
            
            const reviewContent = review.reviewContent.toLowerCase();
            const reviewTitle = review.reviewTitle ? review.reviewTitle.toLowerCase() : '';
            const combinedText = reviewContent + ' ' + reviewTitle;
            let score = 0;
            
            // Exact code match is highest priority
            if (listing.code && combinedText.includes(listing.code.toLowerCase())) {
                score += 10;
            }
            
            // Title words matches
            if (listing.title) {
                const titleWords = listing.title.toLowerCase().split(/\s+/);
                titleWords.forEach(word => {
                    if (word.length > 3 && combinedText.includes(word)) {
                        score += 2;
                    }
                });
            }
            
            // Keyword matches
            keywords.forEach(keyword => {
                if (keyword.length > 3 && combinedText.includes(keyword)) {
                    score += 1;
                }
            });
            
            // Location matches
            if (listing.origin && combinedText.includes(listing.origin.toLowerCase())) {
                score += 3;
            }
            if (listing.destination && combinedText.includes(listing.destination.toLowerCase())) {
                score += 3;
            }
            
            // Bonus for higher rating
            score += (review.reviewRating - 4);
            
            return score;
        },
        
        // Filter listings based on criteria
        filterListings(filters: ListingFilters) {
            try {
                // Create cache key from filters
                const cacheKey = `filter:${JSON.stringify(filters)}`;
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                // Apply filters
                let filtered = [...this.listings];
                
                if (filters.origin) {
                    filtered = filtered.filter(listing => listing.origin === filters.origin);
                }
                
                if (filters.destination) {
                    filtered = filtered.filter(listing => listing.destination === filters.destination);
                }
                
                if (filters.listing_type && filters.listing_type.length > 0) {
                    filtered = filtered.filter(listing => 
                        filters.listing_type.includes(listing.listing_type)
                    );
                }
                
                if (filters.minPrice !== undefined || filters.maxPrice !== undefined) {
                    filtered = filtered.filter(listing => {
                        // Use USD as default for price filtering
                        const basePrice = listing.ratePlans['USD']?.base || 0;
                        
                        if (filters.minPrice !== undefined && filters.maxPrice !== undefined) {
                            return basePrice >= filters.minPrice && basePrice <= filters.maxPrice;
                        } else if (filters.minPrice !== undefined) {
                            return basePrice >= filters.minPrice;
                        } else if (filters.maxPrice !== undefined) {
                            return basePrice <= filters.maxPrice;
                        }
                        
                        return true;
                    });
                }
                
                if (filters.keywords && filters.keywords.length > 0) {
                    filtered = filtered.filter(listing => {
                        // Check if listing has any of the keywords
                        const hasKeyword = filters.keywords.some(keyword => {
                            // Check in title
                            if (listing.title.toLowerCase().includes(keyword.toLowerCase())) {
                                return true;
                            }
                            
                            // Check in description
                            if (listing.content.seo_description.toLowerCase().includes(keyword.toLowerCase())) {
                                return true;
                            }
                            
                            // Check in meta keywords
                            if (listing.content.meta_keywords && listing.content.meta_keywords.some(
                                meta => meta.toLowerCase().includes(keyword.toLowerCase())
                            )) {
                                return true;
                            }
                            
                            return false;
                        });
                        
                        return hasKeyword;
                    });
                }
                
                if (filters.serviceProvider) {
                    filtered = filtered.filter(listing => 
                        listing.serviceProvider?.name === filters.serviceProvider
                    );
                }
                
                if (filters.hasFeaturedHighlights) {
                    filtered = filtered.filter(listing => 
                        listing.content.featured_highlights && 
                        listing.content.featured_highlights.length > 0
                    );
                }
                
                // Cache the result
                cache.set(cacheKey, filtered);
                
                return filtered;
            } catch (error) {
                console.error('Error filtering listings:', error);
                return [];
            }
        },
        
        // Sort listings by different criteria
        sortListings(listings: Listing[], sortBy: SortOption = 'popularity') {
            try {
                const cacheKey = `sort:${sortBy}:${listings.map(l => l.code).join(',')}`;
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                // Create a copy to avoid mutating the original
                const sorted = [...listings];
                
                switch (sortBy) {
                    case 'price_asc':
                        sorted.sort((a, b) => {
                            const priceA = a.ratePlans['USD']?.base || 0;
                            const priceB = b.ratePlans['USD']?.base || 0;
                            return priceA - priceB;
                        });
                        break;
                    
                    case 'price_desc':
                        sorted.sort((a, b) => {
                            const priceA = a.ratePlans['USD']?.base || 0;
                            const priceB = b.ratePlans['USD']?.base || 0;
                            return priceB - priceA;
                        });
                        break;
                        
                    case 'name_asc':
                        sorted.sort((a, b) => a.title.localeCompare(b.title));
                        break;
                        
                    case 'name_desc':
                        sorted.sort((a, b) => b.title.localeCompare(a.title));
                        break;
                        
                    case 'popularity':
                    default:
                        // Sort by isBestSeller first, then reviewCount
                        sorted.sort((a, b) => {
                            if (a.isBestSeller && !b.isBestSeller) return -1;
                            if (!a.isBestSeller && b.isBestSeller) return 1;
                            return (b.reviewCount || 0) - (a.reviewCount || 0);
                        });
                        break;
                }
                
                // Cache the result
                cache.set(cacheKey, sorted);
                
                return sorted;
            } catch (error) {
                console.error('Error sorting listings:', error);
                return listings; // Return original if error
            }
        },
        
        // Get service providers list
        getServiceProviders() {
            try {
                const cacheKey = 'serviceProviders';
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                // Extract unique service providers
                const providers = [...new Set(
                    this.listings
                        .filter(listing => listing.serviceProvider?.name)
                        .map(listing => listing.serviceProvider.name)
                )].sort();
                
                // Cache the result
                cache.set(cacheKey, providers);
                
                return providers;
            } catch (error) {
                console.error('Error getting service providers:', error);
                return [];
            }
        },
        
        // Get all listing types
        getListingTypes() {
            try {
                const cacheKey = 'listingTypes';
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                // Extract unique listing types
                const types = [...new Set(
                    this.listings
                        .filter(listing => listing.listing_type)
                        .map(listing => listing.listing_type)
                )].sort();
                
                // Cache the result
                cache.set(cacheKey, types);
                
                return types;
            } catch (error) {
                console.error('Error getting listing types:', error);
                return [];
            }
        },
        
        // Get price range
        getPriceRange() {
            try {
                const cacheKey = 'priceRange';
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                // Get min and max prices
                let minPrice = Infinity;
                let maxPrice = -Infinity;
                
                this.listings.forEach(listing => {
                    const price = listing.ratePlans['USD']?.base || 0;
                    if (price < minPrice) minPrice = price;
                    if (price > maxPrice) maxPrice = price;
                });
                
                const result = {
                    min: minPrice === Infinity ? 0 : minPrice,
                    max: maxPrice === -Infinity ? 1000 : maxPrice
                };
                
                // Cache the result
                cache.set(cacheKey, result);
                
                return result;
            } catch (error) {
                console.error('Error getting price range:', error);
                return { min: 0, max: 1000 };
            }
        },
        
        // Clean up expired cache
        cleanupCache() {
            cache.cleanup();
        }
    },
    getters: {
        // Add error handling to getters
        getListingsBySlug: (state) => (slug: string) => {
            try {
                if (!slug) return null;
                
                const cacheKey = `listing:${slug}`;
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                const listing = state.listings.find(listing => listing.slug.includes(slug)) || null;
                
                // Cache the result
                if (listing) {
                    cache.set(cacheKey, listing);
                }
                
                return listing;
            } catch (error) {
                console.error(`Error getting listing by slug "${slug}":`, error);
                return null;
            }
        },
        getBestsellers: (state) => {
            try {
                const cacheKey = 'bestsellers';
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                const bestsellers = getRandomSubset(6, state.listings.filter(listing => listing.isBestSeller));
                
                // Cache the result
                cache.set(cacheKey, bestsellers);
                
                return bestsellers;
            } catch (error) {
                console.error('Error getting bestsellers:', error);
                return [];
            }
        },
        getCurratedListings: (state) => (slug: string) => {
            try {
                if (!slug) return null;
                
                const cacheKey = `curated:${slug}`;
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                const curatedListing = state.curratedListings.find(listing => listing?.slug.includes(slug));
                const result = curatedListing || slugLibrary(slug, state.listings);
                
                // Cache the result
                if (result) {
                    cache.set(cacheKey, result);
                }
                
                return result;
            } catch (error) {
                console.error(`Error getting curated listing by slug "${slug}":`, error);
                return null;
            }
        },
        getSuggestions: (state) => (code: string, origin: string, destination: string) => {
            try {
                if (!code && !origin && !destination) return [];
                
                const cacheKey = `suggestions:${code}:${origin}:${destination}`;
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                const listings = state.listings.filter(listing => 
                    (listing.destination === origin || 
                     listing.destination === destination || 
                     listing.origin === origin || 
                     listing.origin === destination) && 
                    listing.code !== code
                );
                
                const suggestions = getRandomSubset(6, listings);
                
                // Cache the result
                cache.set(cacheKey, suggestions);
                
                return suggestions;
            } catch (error) {
                console.error('Error getting suggestions:', error);
                return [];
            }
        },
        // Search by keyword across all fields
        searchListings: (state) => (keyword: string) => {
            try {
                if (!keyword || keyword.trim() === '') return [];
                
                const cacheKey = `search:${keyword}`;
                
                // Check cache first
                const cachedResult = cache.get(cacheKey);
                if (cachedResult) return cachedResult;
                
                keyword = keyword.toLowerCase();
                
                const results = state.listings.filter(listing => {
                    // Check in title
                    if (listing.title.toLowerCase().includes(keyword)) {
                        return true;
                    }
                    
                    // Check in description
                    if (listing.content.seo_description.toLowerCase().includes(keyword)) {
                        return true;
                    }
                    
                    // Check in meta keywords
                    if (listing.content.meta_keywords && listing.content.meta_keywords.some(
                        meta => meta.toLowerCase().includes(keyword)
                    )) {
                        return true;
                    }
                    
                    // Check in featured highlights
                    if (listing.content.featured_highlights && listing.content.featured_highlights.some(
                        highlight => highlight.toLowerCase().includes(keyword)
                    )) {
                        return true;
                    }
                    
                    // Check in service provider
                    if (listing.serviceProvider?.name.toLowerCase().includes(keyword)) {
                        return true;
                    }
                    
                    return false;
                });
                
                // Cache the result
                cache.set(cacheKey, results);
                
                return results;
            } catch (error) {
                console.error(`Error searching listings for "${keyword}":`, error);
                return [];
            }
        }
    },
    persist: {
        storage: piniaPluginPersistedstate.cookies({ sameSite: 'lax' }),
    }
});